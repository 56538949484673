import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

// ubah gara2 erorr : ReactDOM.render is no longer supported


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App/>);
// ReactDOM.render(
//   <React.StrictMode>
    
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
