export const paths = {
  freecourse: "/freecourse",
  masterclass: "/masterclass",
  masterclassojt: "/masterclassojt",
  learningpath: "/learningpath",
  prakerja: "/prakerja",
  portofolio: "/portofolio",
  kampusmerdeka: "/kampusmerdeka",
  internationalcertification: "/internationalcertification",
  nationalcertification: "/nationalcertification",
  testimonial: "/testimonial",
  collaboration: "/colaboration",
  
};
