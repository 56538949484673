import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../components/Layout/Wrapper';

export const UserInfoPage = () => {
    // We'll use the history to navigate the user
    // programmatically later on (we're not using it yet)
    const history = useNavigate();

    // These states are bound to the values of the text inputs
    // on the page (see JSX below). 
    const [favoriteFood, setFavoriteFood] = useState('');
    const [hairColor, setHairColor] = useState('');
    const [bio, setBio] = useState('');

    // These state variables control whether or not we show
    // the success and error message sections after making
    // a network request (see JSX below).
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    // This useEffect hook automatically hides the
    // success and error messages after 3 seconds when they're shown.
    // Just a little user interface improvement.
    useEffect(() => {
        if (showSuccessMessage || showErrorMessage) {
            setTimeout(() => {
                setShowSuccessMessage(false);
                setShowErrorMessage(false);
            }, 3000);
        }
    }, [showSuccessMessage, showErrorMessage]);

    const saveChanges = async () => {
        // Send a request to the server to
        // update the user's info with any changes we've
        // made to the text input values
        alert('Save functionality not implemented yet');
    }

    const logOut = () => {
        // We'll want to log the user out here
        // and send them to the "login page"
        alert('Log out functionality not implemented yet');
    }
    
    const resetValues = () => {
        // Reset the text input values to
        // their starting values (the data we loaded from the server)
        alert('Reset functionality not implemented yet');
    }
    
    // And here we have the JSX for our component. It's pretty straightforward
    return (
        <>
            <Wrapper>
                <h1>PrivatePage</h1>
            </Wrapper>
        </>
        // <div className="content-container">
        //     <h1>Info for ______</h1>
        //     {showSuccessMessage && <div className="success">Successfully saved user data!</div>}
        //     {showErrorMessage && <div className="fail">Uh oh... something went wrong and we couldn't save changes</div>}
        //     <label>
        //         Favorite Food:
        //         <input
        //             onChange={e => setFavoriteFood(e.target.value)}
        //             value={favoriteFood} />
        //     </label>
        //     <label>
        //         Hair Color:
        //         <input
        //             onChange={e => setHairColor(e.target.value)}
        //             value={hairColor} />
        //     </label>
        //     <label>
        //         Bio:
        //         <input
        //             onChange={e => setBio(e.target.value)}
        //             value={bio} />
        //     </label>
        //     <hr />
        //     <button onClick={saveChanges}>Save Changes</button>
        //     <button onClick={resetValues}>Reset Values</button>
        //     <button onClick={logOut}>Log Out</button>
        // </div>
    );
}